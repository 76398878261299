<template>
  <a-card class="mb10">
    <a-button size='small' @click="()=>{$router.go(-1)}">
      <LeftOutlined />返回
    </a-button>
    <span class="ml15 card_title" v-if='$route.query.type == "look"'>短信推送模板详情</span>
    <span class="ml15 card_title" v-else>{{$route.query.type == 'add' ? '添加':'编辑'}}短信推送模板</span>
  </a-card>
  <a-card :bordered="false">
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="send_business" label="消息应用" name="send_business">
          <a-select v-model:value="form.send_business" placeholder="请选择消息应用">
            <a-select-option :value="1">积分商城</a-select-option>
            <a-select-option :value="2">在线订货</a-select-option>
            <a-select-option :value="3">平台通知</a-select-option>
            <a-select-option :value="4">云店商城</a-select-option>
            <a-select-option :value="5">智能防窜</a-select-option>
            <a-select-option :value="6">智能营销</a-select-option>
            <a-select-option :value="7">资产</a-select-option>
            <a-select-option :value="8">无忧售后</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="send_type" label="短信类型" name="send_type">
          <a-select v-model:value="form.send_type" placeholder="请选择短信类型">
            <a-select-option :value="0">验证码</a-select-option>
            <a-select-option :value="1">短信通知</a-select-option>
            <a-select-option :value="2">推广短信</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="name" label="模板名称" name="name">
          <a-input v-model:value="form.name" placeholder='请输入模板名称' />
        </a-form-item>
        <a-form-item ref="identification" label="模板标识" name="identification">
          <a-input v-model:value="form.identification" placeholder='请输入模板标识'  />
        </a-form-item>
        <a-form-item ref="trigger_condition" label="触发条件" name="trigger_condition">
          <a-input v-model:value="form.trigger_condition" placeholder='请输入触发条件'  />
        </a-form-item>
        <a-form-item ref="explain" label="触发时间说明" name="explain">
          <a-input v-model:value="form.explain" placeholder='请输入触发时间说明'  />
        </a-form-item>
        <a-form-item ref="content" label="模板内容" name="content" >
          <a-textarea v-model:value="form.content" showCount :maxlength="200" placeholder="请输入模板内容" :rows="4" />
        </a-form-item>
        <a-form-item ref="params" label="参数" name="params">
          <a-input v-model:value="form.params" placeholder='多个参数请用逗号“，”隔开。例：order_no，day，code'  />
        </a-form-item>
        <a-form-item ref="remark" label="场景说明" name="remark" >
          <a-textarea v-model:value="form.remark" showCount :maxlength="200" placeholder="请输入场景说明" :rows="4" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
          <a-button class="m-r-20" @click="()=>{$router.go(-1)}">
            取消
          </a-button>
          <a-button type="primary" @click="onSubmit" :loading='loading'>
            保存
          </a-button>
        </a-form-item>
      </a-form>
  </a-card>
</template>
<script>
  import { LeftOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject } from 'vue';
  import { message } from 'ant-design-vue';
  import { $iscode } from "@/utils/app";
  import { smsSysAdd, smsSysEdit, getSmsSysMore} from "@/api/message";
  // 初始化默认筛选项数值
  let defForm = {
    send_business: undefined,
    send_type: undefined,
    name: '',
    trigger_condition:'',
    explain:'',
    content:'',
    remark:'',
    params:'',
    identification:''
  }
  export default {
    components: {LeftOutlined},
    setup(props,{emit}) {
      //pass
      const form = ref({ ...defForm });
      const state = reactive({
        loading:false,
        typeList:[],
        formData:{},
        labelCol: { span: 3 },
        wrapperCol: { span: 10 },
        rules: {
          send_business:[
          { required: true, message: '请选择消息应用'}
          ],
          send_type:[
          { required: true, message: '请选择短信类型'}
          ],
          name: [
            { required: true, message: '请输入模板名称'}
          ],
          identification: [
            { required: true, message: '请输入模板标识'}
          ],
          trigger_condition: [
            { required: true, message: '请输入触发条件'}
          ],
          explain: [
            { required: true, message: '请输入触发时间说明'}
          ],
          content: [
            { required: true, message: '请输入模板内容'}
          ],
          // params: [
          //   { required: true, message: '请输入参数'}
          // ],
          remark: [
            { required: true, message: '请输入场景说明'}
          ],
        }
      });
      const $route = inject("$route");
      const $router = inject("$router");
      const _lodash = inject("_lodash");
      const getMore = async () => {
        const hide = message.loading("正在加载中...", 0);
        var res = await getSmsSysMore($route.query.id).then((res) => res.data).catch(error => error);
        if ($iscode(res)) {
          res.data.params = res.data.params && typeof res.data.params === 'string' ? JSON.parse(res.data.params) : res.data.params;
          res.data.params = _lodash.join(res.data.params, ',');
          form.value = res.data;
        } else {
          message.error(res.msg);
        }
        setTimeout(hide);
      };
      const ruleForm = ref()
      const onSubmit = () => {
        ruleForm.value.validate().then(async() => {
          const hide = message.loading('正在加载中...', 0);
          state.loading = true
          form.value.params = _lodash.split(form.value.params, ',');
          let res
          if ($route.query.type == 'edit') {
            res = await smsSysEdit(form.value).then(res => res.data).catch(error => error);
          } else {
            res = await smsSysAdd(form.value).then(res => res.data).catch(error => error);
          }
          if ($iscode(res)) {
            message.success(res.msg);
            setTimeout(() => {
              $router.go(-1);
            }, 1000);
          } else {
            message.error(res.msg);
          }
          setTimeout(hide);
          state.loading = false
        }).catch(error => {
            console.log('error', error);
        });
      }
      onMounted(() => {
        if($route.query.type == 'edit'){
          getMore();
        }
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit
      }
    }
  }
</script>
<style>
</style>